import React, { Component } from "react";

import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import LoginForm from "./LoginForm";
import { isAdmin } from "./../../../versionInfo";
import Burger from "./../menu/Burger";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      message: undefined,
    };
  }

  signedIn = () => {
    console.log("signedIn, from login");
    this.componentDidMount();
  };
  componentDidMount() {
    console.log(
      "inside login componenet, auth is : " +
        JSON.stringify(this.props.firebase.auth)
    );
    this.props.firebase.auth.onAuthStateChanged(
      function (user) {
        if (user) {
          console.log("user signed in, should forward");
          if (isAdmin(this.props.firebase.auth.currentUser.email)) {
            console.log("admin");
            this.props.history.push("/dashboard");
          } else {
            this.props.history.push("/employee-dashboard");
          }
        } else {
          console.log("user signed out");
        }
      }.bind(this)
    );
  }
  render() {
    return (
      <div className="appContainer d-flex flex-column">
        <Burger
          pageWrapId={"frontEndContainer"}
          outerContainerId={"appContainer"}
          page="login"
        />
        <div className="frontEndContainer">
          {" "}
          <div className="loginFormContainer">
            <LoginForm signedIn={this.signedIn} fire={this.props.firebase} />
          </div>
        </div>{" "}
      </div>
    );
  }
}

export default compose(withFirebase, withRouter)(Login);
