import React, { Component } from "react";
import "./progressBar.css";

export default class ProgressBar extends Component {
  constructor() {
    super();
    this.state = { value: 0 };
  }
  setValue = () => {
    setTimeout(() => {
      let value = this.props.percentage * this.props.width;
      this.setState({ value: value });
    }, 1000);
  };
  componentDidMount() {
    this.setValue();
    console.log(JSON.stringify(this.state.value) + " is the value");
  }
  render() {
    return (
      <div className="progress-div" style={{ width: this.props.width }}>
        <div style={{ width: `${this.state.value}px` }} className="progress" />
      </div>
    );
  }
}
