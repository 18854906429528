import React, { Component } from "react";
import BlinkingCursor from "../../common/BlinkingCursor";
import "./typingtext.css";

export default class TypingText extends Component {
  constructor() {
    super();
    this.state = {
      taglines: undefined,
      iterator: 0,
      index: 0,
      output: "",
    };
  }
  typeTimeoutLong = setTimeout(this.transformType, 300);
  typeTimeoutShort = setTimeout(this.transformType, 70);
  typeTimeoutReallyLong = setTimeout(this.nextWord, 4000);
  setType = () => {
    let taglines = this.props.taglines;
    console.log("inside settype: taglines are:  " + JSON.stringify(taglines));
    this.setState({ taglines: taglines }, () => {
      this.nextWord();
    });
  };
  componentDidMount() {
    this.setType();
  }
  transformType = () => {
    let iterator = this.state.iterator;
    let output = this.state.output;

    let tagline = this.state.taglines[this.state.index].tagline;

    if (iterator < tagline.length) {
      output += tagline.charAt(iterator);

      iterator++;
      if (tagline.charAt(iterator - 1) === " ") {
        this.setState({ output: output, iterator: iterator }, () => {
          this.typeTimeoutLong = setTimeout(this.transformType, 300);
        });
      } else {
        this.setState({ output: output, iterator: iterator }, () => {
          this.typeTimeoutShort = setTimeout(this.transformType, 70);
        });
      }
    } else {
      let index = this.state.index;
      index = index + 1;
      if (this.state.taglines[index] == undefined) {
        index = 0;
      }
      this.setState({ index: index, iterator: 0 }, () => {
        this.typeTimeoutReallyLong = setTimeout(this.nextWord, 4000);
      });
    }
  };
  nextWord = () => {
    this.setState({ iterator: 0, output: "" }, this.transformType());
  };
  componentWillUnmount() {
    clearTimeout(this.typeTimeoutLong);
    clearTimeout(this.typeTimeoutReallyLong);
    clearTimeout(this.typeTimeoutShort);
  }
  render() {
    return (
      <div>
        <span className="typingText">
          {this.state.output}
          <BlinkingCursor />
        </span>
      </div>
    );
  }
}
