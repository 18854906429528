import React from 'react'

export default function Alert(props) {
    setTimeout(function() { props.closeMessage(); }, 5000);
    switch (props.alertType){
        case 'danger':
    return (<div className="animated bounceIn alert alert-danger message">{props.message}</div>);
    case 'primary':
        return (<div className="animated bounceIn alert alert-primary message">{props.message}</div>);
        default:
            return (<div className="animated bounceIn alert alert-primary message">{props.message}</div>);
    }
  
}
