import React, { Component } from "react";
import { stack as Menu } from "react-burger-menu";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import logo from "./../../frontEnd/logo.png";
import "./menu.css";

export default class Burger extends Component {
  constructor(props) {
    super();
    this.state = {
      menuOpen: false,
    };
  }
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }
  render() {
    let menuContent = {};
    if (this.props.page == "landing") {
      menuContent = (
        <Menu
          pageWrapId={this.props.pageWrapId}
          outerContainerId={this.props.outerContainerId}
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          disableAutoFocus
        >
          <img src={logo} className="burgerLogo" />

          <Link
            onClick={() => {
              this.closeMenu();
            }}
            className="menu-item burgerItem"
            to="/"
          >
            Home
          </Link>
          <a
            className="menu-item burgerItem"
            onClick={() => {
              this.closeMenu();
              this.props.scrollTo("about");
            }}
          >
            About
          </a>
          <a
            className="menu-item burgerItem"
            onClick={() => {
              this.closeMenu();
              this.props.scrollTo("contact");
            }}
          >
            Contact
          </a>
          <Link
            onClick={() => {
              this.closeMenu();
            }}
            className="menu-item burgerItem"
            to="/login"
          >
            Login
          </Link>
        </Menu>
      );
    } else {
      menuContent = (
        <Menu
          pageWrapId={this.props.pageWrapId}
          outerContainerId={this.props.outerContainerId}
          disableAutoFocus
        >
          <img src={logo} className="burgerLogo" />
          <Link
            onClick={() => {
              this.closeMenu();
            }}
            className="menu-item burgerItem"
            to="/"
          >
            Home
          </Link>
          <Link
            onClick={() => {
              this.closeMenu();
            }}
            className="menu-item burgerItem"
            to="/#about"
          >
            About
          </Link>
          <Link
            onClick={() => {
              this.closeMenu();
            }}
            className="menu-item burgerItem"
            to="/#contact"
          >
            Contact
          </Link>
          <Link
            onClick={() => {
              this.closeMenu();
            }}
            className="menu-item burgerItem"
            to="/login"
          >
            Login
          </Link>
        </Menu>
      );
    }
    return <div>{menuContent}</div>;
  }
}
