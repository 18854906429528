import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.png";
import "./footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <hr />
      <Link to="/">
        <img src={logo} className="footerLogo" />
      </Link>
    </div>
  );
}
