export const admin = ["kozbrett@gmail.com"];

export const version = "dev";

export const devFirebaseConfig = {
  apiKey: "AIzaSyANf5cZ-OHHK3wydFrunozCtP5HVGw47ic",
  authDomain: "design-4e22c.firebaseapp.com",
  projectId: "design-4e22c",
  storageBucket: "design-4e22c.appspot.com",
  messagingSenderId: "133998742996",
  appId: "1:133998742996:web:00e75479914172cd6d9a41",
  measurementId: "G-TKV49NFNHW",
};
export const prodFirebaseConfig = {};

let firebaseConfigObject;
if (version === "dev") {
  firebaseConfigObject = devFirebaseConfig;
} else {
  firebaseConfigObject = prodFirebaseConfig;
}
export var firebaseConfig = firebaseConfigObject;

export function isAdmin(email) {
  if (email === admin) {
    return true;
  } else {
    return false;
  }
}
