import React, { useState, useEffect } from "react";

export default function BlinkingCursor() {
  const [blink, setBlink] = useState(0);

  useEffect(() => {
    let id;

    id = window.setTimeout(() => {
      if (blink == 0) {
        setBlink(1);
      } else {
        setBlink(0);
      }
    }, 300);
    return () => {
      window.setTimeout(id);
    };
  });

  return (
    <span className="blinking-cursor" style={{ opacity: blink }}>
      |
    </span>
  );
}
