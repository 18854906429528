import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Firebase, { FirebaseContext } from "./firebase/index";

import "firebase/firestore";
import "firebase/auth";
import "./typography.css";

const FirebaseInstance = new Firebase();

serviceWorker.register();

ReactDOM.render(
  <FirebaseContext.Provider value={FirebaseInstance}>
    <App style={{ height: "100%" }} />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
