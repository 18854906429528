import React, { Component } from "react";

export default class NumberCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      years: 0,
      interval: 70,
    };
  }
  iterate = () => {
    let years = this.state.years;
    console.log("years are currently: " + this.state.years);
    years = years + 1;
    this.setState({ years: years }, () => {
      if (this.state.years < this.props.years) {
        setTimeout(() => {
          this.iterate();
        }, this.state.interval);
      } else {
        console.log("hit limit, counted up");
      }
    });
  };
  setInterval = () => {
    if (this.state.interval !== 70) {
      this.iterate();
    } else {
      let interval = 2500 / this.props.years;
      this.setState({ interval: interval }, () => {
        this.iterate();
      });
    }
  };
  componentDidMount() {
    this.setInterval();
  }
  render() {
    return <span className="numberCounter">{this.state.years}</span>;
  }
}
