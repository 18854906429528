import React, { Component } from "react";
import "./landing.css";
import "./../../../animate.css";
import TypingText from "./TypingText";
import Particles from "react-particles-js";
import Burger from "./../menu/Burger";
import logo from "./../logo.png";
import Footer from "./../Footer";
import NumberCounter from "./NumberCounter";
import ProgressBar from "./ProgressBar";
import ContactForm from "./ContactForm";
import LoadingScreen from "./../../common/LoadingScreen";
import Alert from "./../../common/Alert";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import shortid from "shortid";
import AOS from "aos";
import "aos/dist/aos.css";
import uncleDunkelsDesktop from "./../portfolioStuff/uncleDunkelsDesktop.jpg";
import uncleDunkelsMobile from "./../portfolioStuff/uncleDunkelsMobile.jpg";
import crossApiaryDesktop from "./../portfolioStuff/crossApiaryDesktop.jpg";
import crossApiaryMobile from "./../portfolioStuff/crossApiaryMobile.jpg";

import truBeeRemovalDesktop from "./../portfolioStuff/truBeeRemovalDesktop.jpg";
import truBeeRemovalMobile from "./../portfolioStuff/truBeeRemovalMobile.jpg";
import beeLightHoneyDesktop from "./../portfolioStuff/beeLightHoneyDesktop.jpg";
import beeLightHoneyMobile from "./../portfolioStuff/beeLightHoneyMobile.jpg";
import colinKozmaDesktop from "./../portfolioStuff/colinKozmaDesktop.jpg";
import colinKozmaMobile from "./../portfolioStuff/colinKozmaMobile.jpg";
import hiltonFarmsDesktop from "./../portfolioStuff/hiltonFarmsDesktop.jpg";
import hiltonFarmsMobile from "./../portfolioStuff/hiltonFarmsMobile.jpg";
import beeHealthyGardensDesktop from "./../portfolioStuff/beeHealthyGardensDesktop.jpg";
import beeHealthyGardensMobile from "./../portfolioStuff/beeHealthyGardensMobile.jpg";
import katieKozmaDesktop from "./../portfolioStuff/katieKozmaDesktop.jpg";
import katieKozmaMobile from "./../portfolioStuff/katieKozmaMobile.jpg";
import signal71Desktop from "./../portfolioStuff/signal71Desktop.jpg";
import signal71Mobile from "./../portfolioStuff/signal71Mobile.jpg";
import me from "./../me.jpg";
import axios from "axios";
const defaultStyles = {
  overflow: "hidden",
  width: "100%",
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.7em",
  fontFamily: "Fruktur', cursive",
  textTransform: "uppercase",
  padding: 0,
};

class Landing extends Component {
  constructor() {
    super();

    this.state = {
      textItems: [],
      iterator: -1,
      featured: [],
      loading: true,
      htmlPercentage: 1.0,
      htmlYears: 24,
      cssPercentage: 0.8,
      cssYears: 20,
      javascriptPercentage: 0.8,
      javascriptYears: 20,
      reactPercentage: 0.5,
      reactYears: 6,
      angularYears: 6,
      angularPercentage: 0.5,
      wordpressPercentage: 0.6,
      wordpressYears: 8,
      javaPercentage: 0.7,
      javaYears: 18,
      contactName: "",
      contactEmail: "",
      contactMessage: "",
      aboutRef: React.createRef(),
      whyHireRef: React.createRef(),
      contactRef: React.createRef(),
      featuredProjectsRef: React.createRef(),
      scrolled: false,
      contactLoading: false,
      mobileImagesToLoad: 9,
      mobileImagesLoaded: 0,
      taglines: [
        { tagline: "Web Applications" },
        { tagline: "Ecommerce Sites" },
        { tagline: "Mobile Applications" },
        { tagline: "Peace Of Mind" },
      ],
    };
  }

  stopAnimation = () => {};
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;
    console.log("scrollTop is : " + scrolled);
    if (scrolled > 0.04) {
      this.setState({ scrolledExperience: true });
    } else {
      this.setState({ scrolledExperience: false });
    }
  };
  checkURL = () => {
    let url = window.location.href;
    if (url.includes("#")) {
      console.log("have hash");
      let newUrl = url.substr(url.indexOf("#") + 1);

      console.log("hash: " + newUrl);
      if (newUrl !== undefined) {
        console.log("calling scrollTo");
        this.setState({ scrolled: true });
        this.scrollTo(newUrl);
      }
    } else {
      console.log("no hash");
    }
  };
  componentDidUpdate() {
    if (!this.state.scrolled) {
      this.checkURL();
    }
  }

  componentDidMount() {
    this.setFeatured();

    AOS.init();
    window.addEventListener("scroll", this.handleScroll);

    console.log(
      "component mounted, match params is: " +
        JSON.stringify(window.location.href)
    );
    this.loadImages();
  }
  loadImages = () => {};
  getRandomKey = () => {
    return shortid.generate();
  };
  imageLoaded = () => {
    console.log("image loaded");
    let imagesLoaded = this.state.mobileImagesLoaded;
    let newImagesLoaded = imagesLoaded + 1;
    if (newImagesLoaded >= this.state.mobileImagesToLoad) {
      //loading:false
      console.log("loaded all images, loading false");
      this.setState({ mobileImagesLoaded: newImagesLoaded, loading: false });
    } else {
      //loading:true
      console.log("not enough images yet");
      this.setState({ mobileImagesLoaded: newImagesLoaded, loading: true });
    }
  };
  setFeatured = () => {
    let crossMobile = new Image();
    crossMobile.src = crossApiaryMobile;
    crossMobile.onload = () => {
      this.imageLoaded("crossMobile");
    };
    let uncleMobile = new Image();
    uncleMobile.src = uncleDunkelsMobile;
    uncleMobile.onload = () => {
      this.imageLoaded("uncleDunkels");
    };
    let katieMobile = new Image();
    katieMobile.src = katieKozmaMobile;
    katieMobile.onload = () => {
      this.imageLoaded("katieMobile");
    };
    let truBeeMobile = new Image();
    truBeeMobile.src = truBeeRemovalMobile;
    truBeeMobile.onload = () => {
      this.imageLoaded("truBeeRemoval");
    };
    let hiltonMobile = new Image();
    hiltonMobile.src = hiltonFarmsMobile;
    hiltonMobile.onload = () => {
      this.imageLoaded("hiltonFarms");
    };
    let beeLightMobile = new Image();
    beeLightMobile.src = beeLightHoneyMobile;
    beeLightMobile.onload = () => {
      this.imageLoaded("beeLight");
    };
    let colinMobile = new Image();
    colinMobile.src = colinKozmaMobile;
    colinMobile.onload = () => {
      this.imageLoaded("colinKozma");
    };
    let signalMobile = new Image();
    signalMobile.src = signal71Mobile;
    signalMobile.onload = () => {
      this.imageLoaded("signalMobile");
    };

    let beeHealthyMobile = new Image();

    beeHealthyMobile.src = beeHealthyGardensMobile;
    beeHealthyMobile.onload = () => {
      this.imageLoaded("beeHealthyMobile");
    };
    this.setState({
      featured: [
        {
          desktop: crossApiaryDesktop,
          mobile: crossMobile.src,
          src: crossApiaryDesktop,
          mobileActive: false,
          classNames: "featuredImage",
          iter: 0,
        },
        {
          desktop: katieKozmaDesktop,
          mobile: katieMobile.src,
          src: katieKozmaDesktop,
          mobileActive: false,
          classNames: "featuredImage",
          iter: 1,
        },
        {
          desktop: truBeeRemovalDesktop,
          mobile: truBeeRemovalMobile,
          src: truBeeRemovalDesktop,
          mobileActive: false,
          classNames: "featuredImage",
          iter: 2,
        },
        {
          desktop: signal71Desktop,
          mobile: signalMobile.src,
          src: signal71Desktop,
          mobileActive: false,
          classNames: "featuredImage",
          iter: 3,
        },
        {
          desktop: beeHealthyGardensDesktop,
          mobile: beeHealthyMobile.src,
          src: beeHealthyGardensDesktop,
          mobileActive: false,
          classNames: "featuredImage",
          iter: 4,
        },
        {
          desktop: colinKozmaDesktop,
          mobile: colinKozmaMobile,
          src: colinKozmaDesktop,
          mobileActive: false,
          classNames: "featuredImage",
          iter: 5,
        },
        {
          desktop: hiltonFarmsDesktop,
          mobile: hiltonFarmsMobile,
          src: hiltonFarmsDesktop,
          mobileActive: false,
          classNames: "featuredImage",
          iter: 6,
        },
        {
          desktop: beeLightHoneyDesktop,
          mobile: beeLightHoneyMobile,
          src: beeLightHoneyDesktop,
          mobileActive: false,
          classNames: "featuredImage",
          iter: 7,
        },
        {
          desktop: uncleDunkelsDesktop,
          mobile: uncleDunkelsMobile,
          src: uncleDunkelsDesktop,
          mobileActive: false,
          classNames: "featuredImage",
          iter: 8,
        },
      ],
    });
  };
  changeFeatured = (iterator) => {
    console.log("changeFeatured: " + iterator);

    let newFeatured = [...this.state.featured];
    console.log(
      "got featured: src is " +
        newFeatured[iterator].src +
        " switching to " +
        this.state.featured[iterator].mobile
    );
    newFeatured[iterator].src = this.state.featured[iterator].mobile;
    newFeatured[iterator].mobileActive = true;
    newFeatured[iterator].classNames = "featuredImage animated fadeInUp";
    this.setState({ featured: newFeatured });
  };
  removeMobile = (iterator) => {
    console.log("changeFeatured: " + iterator);

    console.log("got featured");
    let newFeatured = [...this.state.featured];
    newFeatured[iterator].src = this.state.featured[iterator].desktop;
    newFeatured[iterator].mobileActive = false;
    newFeatured[iterator].classNames = "featuredImage animated fadeInDown";
    this.setState({ featured: newFeatured });
  };
  toggleMobile = (iterator) => {
    let newFeatured = [...this.state.featured];
    if (this.state.featured[iterator].mobileActive) {
      newFeatured[iterator].mobileActive = false;
    } else {
      newFeatured[iterator].mobileActive = true;
    }
    this.setState({ featured: newFeatured });
  };
  checkContact = () => {
    console.log("inside checkContact");
    if (
      this.state.contactName !== "" &&
      this.state.contactEmail !== "" &&
      this.state.contactMessage !== ""
    ) {
      this.setState({ contactValid: true });
    } else {
      this.setState({ contactValid: false });
    }
  };
  contactChange = (event) => {
    console.log("inside contactChange: " + event.target.value);
    this.setState(
      { [event.target.name]: event.target.value, contactValid: false },
      () => {
        this.checkContact();
      }
    );
  };
  contactSubmit = async () => {
    console.log("inside submitForm");
    this.setState({ contactLoading: true });
    let message = {
      name: this.state.contactName,
      email: this.state.contactEmail,

      message: this.state.contactMessage,
      to: "kozbrett@gmail.com",
    };

    console.log("message is : " + JSON.stringify(message));
    await axios
      .post("https://us-central1-design-4e22c.cloudfunctions.net/sendMail", {
        message,
      })
      .then(
        function (res) {
          console.log("res from submitForm is : " + JSON.stringify(res));
          this.setState({
            contactStatusMessage:
              "Thanks for filling out the form. I'll be in contact as soon as possible.",
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactMessage: "",
            messageType: "success",
            contactLoading: false,
          });
        }.bind(this)
      )
      .catch(function (err) {
        this.setState({
          contactStatusMessage:
            "There Was An Error Accessing The Server. Please Try To Send Your Message Again, Or Email Brett@BrettKozmaDesigns.com",
          messageType: "danger",
        });
      });
  };
  closeMessage = () => {
    this.setState({ contactStatusMessage: undefined, messageType: undefined });
  };
  scrollTo = (obj) => {
    console.log("inside scrollTo");
    switch (obj) {
      case "featuredProjects":
        this.state.featuredProjectsRef.current.scrollIntoView({
          behavior: "smooth",
        });
        break;
      case "whyHireRef":
        this.state.whyHireRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        if (this.state.contactRef.current !== null) {
          this.state.contactRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
          const contactNewRef = document.getElementById("contact");
          if (contactNewRef) {
            contactNewRef.scrollIntoView({ behavior: "smooth" });
          }
        }

        break;
      case "about":
        console.log("case about");
        if (this.state.aboutRef.current !== null) {
          this.state.aboutRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
          console.log(
            "aboutRef is null: " + JSON.stringify(this.state.aboutRef)
          );
        }

        break;
      default:
        break;
    }
  };
  render() {
    let contactStatusMessage;
    if (this.state.contactStatusMessage !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      contactStatusMessage = (
        <Alert
          closeMessage={this.closeMessage}
          alertType={messageType}
          message={this.state.contactStatusMessage}
        />
      );
    } else {
      contactStatusMessage = "";
    }
    return (
      <div>
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div id="outerContainer" className="landingContainer">
            <Particles
              params={{
                particles: {
                  number: {
                    value: 50,
                  },
                  size: {
                    value: 3,
                  },
                },
                interactivity: {
                  events: {
                    onhover: {
                      enable: true,
                      mode: "repulse",
                    },
                  },
                },
              }}
            />
            <Burger
              pageWrapId={"overParticles"}
              outerContainerId={"outerContainer"}
              scrollTo={this.scrollTo}
              page="landing"
            />
            <div className="logoContainer">
              <img src={logo} />
            </div>
            <div id="overParticles" className="overParticles">
              <div>Websites And</div>
              <div>
                <TypingText taglines={this.state.taglines} />
              </div>{" "}
              <div> For Small Businesses</div>
            </div>
            <div className="buttonContainer">
              <a
                className="landingButton"
                onClick={() => {
                  this.scrollTo("featuredProjects");
                }}
              >
                PORTFOLIO
              </a>
              <a
                className="landingButton"
                onClick={() => {
                  this.scrollTo("contact");
                }}
              >
                CONTACT
              </a>
            </div>

            <div id="featured">
              <div id="whyHire" ref={this.state.whyHireRef}>
                <h4 className="display-4">WHY HIRE BK DESIGNS?</h4>
                <div className="container container-fluid">
                  <div className="row">
                    {this.state.scrolledExperience ? (
                      <div className="col-lg-6 col-md-12 experience">
                        <p className="lead">EXPERIENCE</p>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="stat">
                              HTML:{" "}
                              <NumberCounter years={this.state.htmlYears} />{" "}
                              Years
                            </p>
                          </div>
                          <div className="col-sm-9">
                            <ProgressBar
                              width={300}
                              percentage={this.state.htmlPercentage}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-3">
                            <p className="stat">
                              CSS: <NumberCounter years={this.state.cssYears} />{" "}
                              Years
                            </p>
                          </div>
                          <div className="col-sm-9">
                            <ProgressBar
                              width={300}
                              percentage={this.state.cssPercentage}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-3">
                            <p className="stat">
                              Javascript:{" "}
                              <NumberCounter
                                years={this.state.javascriptYears}
                              />{" "}
                              Years
                            </p>
                          </div>
                          <div className="col-sm-9">
                            <ProgressBar
                              width={300}
                              percentage={this.state.javascriptPercentage}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-3">
                            <p className="stat">
                              REACT:{" "}
                              <NumberCounter years={this.state.reactYears} />{" "}
                              Years
                            </p>
                          </div>
                          <div className="col-sm-9">
                            {" "}
                            <ProgressBar
                              width={300}
                              percentage={this.state.reactPercentage}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-3">
                            <p className="stat">
                              ANGULAR:{" "}
                              <NumberCounter years={this.state.angularYears} />{" "}
                              Years
                            </p>
                          </div>
                          <div className="col-sm-9">
                            <ProgressBar
                              width={300}
                              percentage={this.state.angularPercentage}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-3">
                            <p className="stat">
                              WordPress:{" "}
                              <NumberCounter
                                years={this.state.wordpressYears}
                              />{" "}
                              Years
                            </p>{" "}
                          </div>
                          <div className="col-sm-9">
                            <ProgressBar
                              width={300}
                              percentage={this.state.wordpressPercentage}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3">
                            <p className="stat">
                              JAVA:{" "}
                              <NumberCounter years={this.state.javaYears} />{" "}
                              Years
                            </p>
                          </div>
                          <div className="col-sm-9">
                            {" "}
                            <ProgressBar
                              width={300}
                              percentage={this.state.javaPercentage}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="experience col-lg-6 col-md-12 shell"></div>
                    )}
                    {this.state.scrolledExperience ? (
                      <div className="col-lg-6 col-md-12 experience">
                        <p className="lead">STATISTICS</p>
                        <hr />
                        <p className="lead">
                          Over <NumberCounter years={150} /> Websites Created
                        </p>
                        <p className="lead">
                          More Than <NumberCounter years={60} /> Satisfied
                          Clients
                        </p>
                        <p className="lead">
                          At Least <NumberCounter years={15} /> Apps Created
                        </p>
                        <p className="lead">
                          <NumberCounter years={24} /> Total Years Developing
                        </p>
                      </div>
                    ) : (
                      <div className="experience col-lg-6 col-md-12 shell"></div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="featured"
                id="featuredProjects"
                ref={this.state.featuredProjectsRef}
              >
                <h4 className="display-4">FEATURED PROJECTS</h4>
                <div
                  data-aos="fade-up"
                  className="fade"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  <div className="row">
                    <div className="mouseControlFeatured col-lg-6">
                      <a
                        onClick={() => {
                          this.toggleMobile(8);
                        }}
                        className="mobileToggle btn"
                      >
                        {this.state.featured[8].mobileActive
                          ? "Desktop View"
                          : "Mobile View"}
                      </a>
                      <br />
                      <img
                        src={
                          this.state.featured[8].mobileActive
                            ? this.state.featured[8].mobile
                            : this.state.featured[8].desktop
                        }
                        className={this.state.featured[8].classNames}
                      />
                    </div>
                    <div className="featuredContentRight col-lg-6">
                      <h1 className="display-6 featuredTitle">
                        UncleDunkels.com
                      </h1>
                      <p className="lead">
                        Uncle Dunkel's is a premium custom birdhouse creator and
                        seller. They needed a website to sell birdhouses,
                        feeders, lures and attractants. I built a mobile ready
                        ecommerce store with Paypal, Venmo, Credit/Debit payment
                        gateways. The total cost of this project was around
                        $1500.
                      </p>
                      <a href="https://www.uncledunkels.com" target="_blank">
                        www.uncledunkels.com
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="zoom-in-right"
                  className="fade"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <div className="row">
                    <div className="featuredContentLeft col-lg-6">
                      <h1 className="display-6 featuredTitle">
                        TruBeeRemoval.com
                      </h1>
                      <p className="lead">
                        Tru Bee Removal is a veteran owned and operated
                        organization that helps other veterans to become skilled
                        beekeepers and bee removal experts. They contacted me in
                        need of a place to get the word out and spread their
                        good will, I was honored to help. The total cost of
                        construction for this site was $199.
                      </p>
                      <a href="https://www.trubeeremoval.com" target="_blank">
                        www.trubeeremoval.com
                      </a>
                    </div>
                    <div className="mouseControlFeatured col-lg-6">
                      <a
                        className="mobileToggle btn"
                        onClick={() => {
                          this.toggleMobile(2);
                        }}
                      >
                        {this.state.featured[2].mobileActive
                          ? "Desktop View"
                          : "Mobile View"}
                      </a>
                      <br />
                      <img
                        src={
                          this.state.featured[2].mobileActive
                            ? this.state.featured[2].mobile
                            : this.state.featured[2].desktop
                        }
                        className={this.state.featured[2].classNames}
                      />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-up"
                  className="fade"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  <div className="row">
                    <div className="mouseControlFeatured col-lg-6">
                      <a
                        onClick={() => {
                          this.toggleMobile(0);
                        }}
                        className="mobileToggle btn"
                      >
                        {this.state.featured[0].mobileActive
                          ? "Desktop View"
                          : "Mobile View"}
                      </a>
                      <br />
                      <img
                        src={
                          this.state.featured[0].mobileActive
                            ? this.state.featured[0].mobile
                            : this.state.featured[0].desktop
                        }
                        className={this.state.featured[0].classNames}
                      />
                    </div>
                    <div className="featuredContentRight col-lg-6">
                      <h1 className="display-6 featuredTitle">
                        CrossApiary.com
                      </h1>
                      <p className="lead">
                        Cross Apiary is a beekeeping equipment supplier out of
                        McBain, Michigan. They wanted a site to advertise and
                        sell their bees and beekeeping equipment and that is
                        what I built for them. The site is complete with a fully
                        functional shopping cart, client accounts and contact
                        forms, and is perfect no matter what device you're
                        viewing it on.
                      </p>
                      <a href="https://www.crossapiary.com" target="_blank">
                        www.crossapiary.com
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="zoom-in-right"
                  className="fade"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <div className="row">
                    <div className="featuredContentLeft col-lg-6">
                      <h1 className="display-6 featuredTitle">
                        KatieKozma.com
                      </h1>
                      <p className="lead">
                        KatieKozma.com is a web app built for my wife, a
                        talented wedding and nature photographer. She wanted a
                        website where she could easily upload her portfolio
                        pictures, create client accounts where they could access
                        their images specifically, and to be able to send
                        invoices. I built that and more, she has the ability to
                        change site content and design with the click of the
                        mouse, upload and manage portfolio and page background
                        images and manage client accounts all without ever
                        having to write any code at all.
                      </p>
                      <a href="https://www.katiekozma.com" target="_blank">
                        www.katiekozma.com
                      </a>
                    </div>
                    <div className="mouseControlFeatured col-lg-6">
                      <a
                        className="mobileToggle btn"
                        onClick={() => {
                          this.toggleMobile(1);
                        }}
                      >
                        {this.state.featured[1].mobileActive
                          ? "Desktop View"
                          : "Mobile View"}
                      </a>
                      <br />
                      <img
                        src={
                          this.state.featured[1].mobileActive
                            ? this.state.featured[1].mobile
                            : this.state.featured[1].desktop
                        }
                        className={this.state.featured[1].classNames}
                      />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-left"
                  className="fade"
                  data-aos-easing="ease-out"
                  data-aos-duration="1000"
                >
                  <div className="row">
                    <div className="mouseControlFeatured col-lg-6">
                      <a
                        className="mobileToggle btn"
                        onClick={() => {
                          this.toggleMobile(6);
                        }}
                      >
                        {this.state.featured[6].mobileActive
                          ? "Desktop View"
                          : "Mobile View"}
                      </a>
                      <br />
                      <img
                        src={
                          this.state.featured[6].mobileActive
                            ? this.state.featured[6].mobile
                            : this.state.featured[6].desktop
                        }
                        className={this.state.featured[6].classNames}
                      />
                    </div>
                    <div className="featuredContentLeft col-lg-6">
                      <h1 className="display-6 featuredTitle">
                        Hilton-Farms.com
                      </h1>
                      <p className="lead">
                        Hilton Farms is a cattle and bee operation out of
                        Kentucky. They needed a website to offer their beef,
                        honey and bee sales. The cost of construction on this
                        site was $199.
                      </p>
                      <a href="https://www.hilton-farms.com" target="_blank">
                        www.hilton-farms.com
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="zoom-in-down"
                  className="fade"
                  data-aos-easing="ease-in-back"
                  data-aos-duration="1300"
                >
                  <div className="row">
                    <div className="featuredContentLeft col-lg-6">
                      <h1 className="display-6 featuredTitle">
                        Signal71Consulting.com
                      </h1>
                      <p className="lead">
                        Signal 7-1 Consulting is an emergency responder
                        consulting firm based in Texas. They wanted a sleek
                        website and didn't want to spend too much. What you see
                        is what you get, and I can promise you they didn't pay
                        anything more than around $700 for the entire project.
                        That's our goal here at BK Designs, competitively priced
                        designs on functional websites and web apps.
                      </p>
                      <a
                        href="https://www.signal71consulting.com"
                        target="_blank"
                      >
                        www.signal71consulting.com
                      </a>
                    </div>
                    <div className="mouseControlFeatured col-lg-6">
                      <a
                        className="mobileToggle btn"
                        onClick={() => {
                          this.toggleMobile(3);
                        }}
                      >
                        {this.state.featured[3].mobileActive
                          ? "Desktop View"
                          : "Mobile View"}
                      </a>
                      <br />
                      <img
                        src={
                          this.state.featured[3].mobileActive
                            ? this.state.featured[3].mobile
                            : this.state.featured[3].desktop
                        }
                        className={this.state.featured[3].classNames}
                      />
                    </div>
                  </div>
                </div>
                <div
                  data-aos="zoom-in"
                  className="fade"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="900"
                >
                  <div className="row">
                    <div className="mouseControlFeatured col-lg-6">
                      <a
                        className="mobileToggle btn"
                        onClick={() => {
                          this.toggleMobile(7);
                        }}
                      >
                        {this.state.featured[7].mobileActive
                          ? "Desktop View"
                          : "Mobile View"}
                      </a>
                      <br />
                      <img
                        src={
                          this.state.featured[7].mobileActive
                            ? this.state.featured[7].mobile
                            : this.state.featured[7].desktop
                        }
                        className={this.state.featured[7].classNames}
                      />
                    </div>

                    <div className="featuredContentLeft col-lg-6">
                      <h1 className="display-6 featuredTitle">
                        BeeLightHoney.com
                      </h1>
                      <p className="lead">
                        Bee Light Honey is a small bee operation out of
                        Seminole, Florida. They contacted me needing to market
                        their business, they needed a logo, labels and an
                        ecommerce website to sell their goods. I built the site
                        and designed 17 labels and a logo for them, keeping the
                        total cost below $2,000.
                      </p>
                      <a href="https://www.beelighthoney.com" target="_blank">
                        www.beelighthoney.com
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="fade-left"
                  className="fade"
                  data-aos-easing="ease-out"
                  data-aos-duration="1000"
                >
                  <div className="row">
                    <div className="mouseControlFeatured col-lg-6">
                      <a
                        className="mobileToggle btn"
                        onClick={() => {
                          this.toggleMobile(5);
                        }}
                      >
                        {this.state.featured[5].mobileActive
                          ? "Desktop View"
                          : "Mobile View"}
                      </a>
                      <br />
                      <img
                        src={
                          this.state.featured[5].mobileActive
                            ? this.state.featured[5].mobile
                            : this.state.featured[5].desktop
                        }
                        className={this.state.featured[5].classNames}
                      />
                    </div>
                    <div className="featuredContentLeft col-lg-6">
                      <h1 className="display-6 featuredTitle">
                        ColinKozma.com
                      </h1>
                      <p className="lead">
                        Colin Kozma is a Metro Detroit Realtor, and my younger
                        brother. He needed a site to get the word out about his
                        real estate venture. I created him a lead generation
                        site and designed a modern looking monogram logo.
                      </p>
                      <a href="https://www.colinkozma.com" target="_blank">
                        www.colinkozma.com
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  data-aos="zoom-in-up"
                  className="fade"
                  data-aos-easing="ease-in-back"
                  data-aos-duration="1300"
                >
                  <div className="row">
                    <div className="featuredContentLeft col-lg-6">
                      <h1 className="display-6 featuredTitle">
                        BeeHealthyGardens.com
                      </h1>
                      <p className="lead">
                        Bee Healthy Gardens is a small bee and honey supplier
                        that was in need of a budget website solution, but they
                        didn't want to let the quality suffer. They came to me a
                        few years ago and I built their website in a few days,
                        for around $700. It has contact forms, image galleries
                        and is optimized for google searches to help drive
                        traffic.
                      </p>
                      <a
                        href="https://www.beehealthygardens.com"
                        target="_blank"
                      >
                        www.beehealthygardens.com
                      </a>
                    </div>
                    <div className="mouseControlFeatured col-lg-6">
                      <a
                        className="mobileToggle btn"
                        onClick={() => {
                          this.toggleMobile(4);
                        }}
                      >
                        {this.state.featured[4].mobileActive
                          ? "Desktop View"
                          : "Mobile View"}
                      </a>
                      <br />
                      <img
                        src={
                          this.state.featured[4].mobileActive
                            ? this.state.featured[4].mobile
                            : this.state.featured[4].desktop
                        }
                        className={this.state.featured[4].classNames}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="section" id="about" ref={this.state.aboutRef}>
                {" "}
                <div className="row">
                  <h4 className="display-4">ABOUT</h4>
                  <div className="col-md-6 aboutImageContainer">
                    <img src={me} className="aboutImage" />
                  </div>
                  <div className="col-md-6 my-auto aboutText">
                    <p className="lead">
                      My name is Brett Kozma. I'm 35 years old and I live in the
                      Upper Peninsula of Michigan. Bees and websites are my two
                      main gigs, sort of a weird combination but it suits me. I
                      try to fill in all of my time not occupied by bees with
                      web projects. I work for anyone and everyone, specializing
                      in websites and web apps for small businesses. I do all of
                      the work myself, which keeps costs way lower than a
                      comparable firm. Shoot me a message if you're interested
                      in hiring me, I'm looking forward to chatting with you!
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="container section"
                id="contact"
                ref={this.state.contactRef}
              >
                {" "}
                <div className="row">
                  <h4 className="display-4">CONTACT</h4>
                  {contactStatusMessage}
                  <div className="col-md-6">
                    <ContactForm
                      name={this.state.contactName}
                      email={this.state.contactEmail}
                      message={this.state.contactMessage}
                      valid={this.state.contactValid}
                      change={this.contactChange}
                      submit={this.contactSubmit}
                      loading={this.state.contactLoading}
                    />
                    {contactStatusMessage}
                  </div>
                  <div className="col-md-6">
                    <div className="contactDetails">
                      <p className="lead dark">
                        <i class="fas fa-user-circle"></i>
                        {" : "}
                        Brett Kozma
                      </p>

                      <p className="lead dark">
                        <i class="fas fa-envelope"></i>
                        {" : "} brett@brettkozmadesigns.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Landing);
