import React, { Component } from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";

import { isAdmin } from "./versionInfo";
import { withFirebase } from "./firebase/index";
import { compose } from "redux";
import Landing from "./components/frontEnd/Landing/Landing";
import Login from "./components/frontEnd/Login/Login";
//components

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navSticky: false,
      scrolled: false,
      signedIn: false,
      loading: true,
      admin: false,
      dashboard: false,
      currentPath: window.location.pathname,
    };
  }
  getSiteContent = () => {
    console.log("inside getSiteContent");
  };
  setupAuthListener = () => {
    this.props.firebase.auth.onAuthStateChanged(
      function (user) {
        if (user) {
          console.log("user signed in, should forward");
          if (isAdmin(user.email)) {
            this.setState({ signedIn: true, admin: true }, () => {
              this.getSiteContent();
            });
          } else {
            this.setState({ signedIn: true, admin: false }, () => {
              this.getSiteContent();
            });
          }
        } else {
          console.log("user signed out");
          this.setState({ signedIn: false }, () => {
            this.getSiteContent();
          });
        }
      }.bind(this)
    );
  };

  componentDidMount() {
    console.log("from appJS, pathname is : " + window.location.pathname);
    this.setupAuthListener();
  }

  render() {
    return (
      <Router>
        <div className="landingContainer">
          <Route exact path="/" component={Landing} />

          <Route exact path="/login" component={Login} />
        </div>
      </Router>
    );
  }
}

export default compose(withFirebase)(App);
